import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEmailFilter, setDateFilter, setStatusFilter } from '../redux/slice/filterSlice';
import { setOrders, setLoading, setError } from '../redux/slice/orderoOfSlice.js';
import { getOfflinePaymentOrder, updateOrderOfStatus } from "../service/api.js";
import '../css/OrderComponet.css'

import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const OrdersOffList = () => {
  const dispatch = useDispatch();
  const orders = useSelector(state => state.orderoffline.orders);
  const filters = useSelector(state => state.filters);
  const loading = useSelector(state => state.orders.loading);
  const error = useSelector(state => state.orders.error);


  const emailOptions = [...new Set(orders.map(order => order.user_email))];
  const dateOptions = [...new Set(orders.map(order => order.created_at.split('T')[0]))];
  const statusOptions = ['processing', 'cancelled'];

  const handleEmailChange = (e) => dispatch(setEmailFilter(e.target.value));
  const handleDateChange = (e) => dispatch(setDateFilter(e.target.value));
  const handleStatusChange = (e) => dispatch(setStatusFilter(e.target.value));

  const filterOrders = () => {

    const data = orders.filter(order => {
      const matchesEmail = filters.email ? order.user_email.includes(filters.email) : true;
      const matchesDate = filters.date ? order.created_at.includes(filters.date) : true;
      const matchesStatus = filters.orderstatus ? order.orderstatus === filters.orderstatus : true;
      return matchesEmail && matchesDate && matchesStatus;
    });
    console.log(data)
    return data
  };

  // const handleStatusUpdate = async (orderId, newStatus) => {

  //   try {
  //     const response = await updateOrderStatus(orderId, newStatus); // Call API to update status
  //     console.log(`Order ${orderId} status updated to ${newStatus}`);
  //     alert(`Order ${orderId} status updated to ${newStatus}`);
  //     const updatedOrders = orders.map(order =>
  //       order.paymemt_id === orderId ? { ...order, orderstatus: newStatus } : order
  //     );
  //     dispatch(setOrders(updatedOrders)); // Update orders in Redux store
  //   } catch (error) {
  //     console.error('Error updating order status:', error);
  //   }
  // };
  
  const handleStatusUpdate = async (orderId, newStatus) => {
    const confirmChange = window.confirm(`Are you sure you want to mark this order as ${newStatus}?`);

    if (confirmChange) {
      try {
        const response = await updateOrderOfStatus(orderId, newStatus); // API call to update order status
        console.log(`Order ${orderId} status updated to ${newStatus}`);
        
        // Show success alert
        // alert(`Order ${orderId} status updated to ${newStatus}`);

        const updatedOrders = orders.map(order =>
          order.paymemt_id === orderId ? { ...order, orderstatus: newStatus } : order
        );
        dispatch(setOrders(updatedOrders)); // Update orders in Redux store
      } catch (error) {
        console.error('Error updating order status:', error);
        
        // Show error alert
        alert('Failed to update order status. Please try again later.');
      }
    } else {
      // If the user cancels the action, show a cancel alert
      alert('Order status update was canceled.');
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      dispatch(setLoading(true)); // Start loading
      try {
        const response = await getOfflinePaymentOrder(); // API call
         console.log(response.data)
        dispatch(setOrders(response.data.data)); // Dispatch orders data
        dispatch(setLoading(false)); // Stop loading
      } catch (error) {
        console.error('Error fetching orders:', error);
        dispatch(setError('Error fetching orders')); // Set error message
        dispatch(setLoading(false)); // Stop loading
      }
    };

    fetchOrders(); // Invoke the async function
  }, [dispatch]);

  return (
    <Box className="orders-list-container" sx={{ p: 3 }}>
    {/* Filter Options */}
    <Typography variant="h4" gutterBottom>
      Filter Options
    </Typography>
    <Box display="flex" gap={2} mb={3}>
      {/* Email Filter */}
      <FormControl fullWidth>
        <InputLabel>Email</InputLabel>
        <Select
          value={filters.email}
          onChange={handleEmailChange}
          label="Email"
        >
          <MenuItem value="">Select Email</MenuItem>
          {emailOptions.map((email) => (
            <MenuItem key={email} value={email}>
              {email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Date Filter */}
      <FormControl fullWidth>
        <InputLabel>Date</InputLabel>
        <Select
          value={filters.date}
          onChange={handleDateChange}
          label="Date"
        >
          <MenuItem value="">Select Date</MenuItem>
          {dateOptions.map((date) => (
            <MenuItem key={date} value={date}>
              {date}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Status Filter */}
      <FormControl fullWidth>
        <InputLabel>Status</InputLabel>
        <Select
          value={filters.status}
          onChange={handleStatusChange}
          label="Status"
        >
          <MenuItem value="">Select Status</MenuItem>
          {statusOptions.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>

    {/* Loading Spinner */}
    {loading && (
      <Box textAlign="center" my={3}>
        <CircularProgress />
      </Box>
    )}

    {/* Error Message */}
    {error && (
      <Typography color="error" textAlign="center" my={3}>
        {error}
      </Typography>
    )}

    {/* Orders List */}
    <Box>
      {filterOrders().map((order) => (
        <Card key={order.payment_id} variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6">{order.user_name}</Typography>
            <Typography>Email: {order.user_email}</Typography>
            <Typography>Amount: {order.amount}</Typography>
            <Typography>Status: {order.status}</Typography>
            <Typography>City: {order.city}</Typography>
            <Typography>Address: {order.street_address}</Typography>
            <Typography>
              Date: {new Date(order.created_at).toLocaleDateString()}
            </Typography>
            <Typography>Order Status: {order.orderstatus}</Typography>

            {/* Product Details */}
            <Box mt={2}>
              <Typography variant="subtitle1">Products:</Typography>
              {JSON.parse(order.products).map((product, index) => (
                <Box key={index} sx={{ mt: 1 }}>
                  <Typography>
                    Product Name: {product.product}
                  </Typography>
                  <Typography>Price: {product.price}</Typography>
                  <Typography>Quantity: {product.quantity}</Typography>
                  <Typography>Selected Options:</Typography>
                  <List dense>
                    {Object.entries(product.selectedOptions).map(([key, value]) => (
                      <ListItem key={key}>
                        <ListItemText primary={`${key}: ${value}`} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Box>

            {/* Action Buttons */}
            <Box display="flex" gap={1} mt={2}>
              <Button
                variant="contained"
                color="info"
                onClick={() => handleStatusUpdate(order.payment_id, 'processing')}
              >
                Mark as Processing
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={() => handleStatusUpdate(order.payment_id, 'cancelled')}
              >
                Cancel Order
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => handleStatusUpdate(order.payment_id, 'delivered')}
              >
                Mark as Delivered
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  </Box>
  );
};

export default OrdersOffList;
