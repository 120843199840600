import React from 'react';
import { Link } from 'react-router-dom'; // For routing to different pages


import './Sidebar.css'; 
const Sidebar = () => {
    return (
      <div className="sidebar">
        <div className="sidebar-header">
          <h2>Admin Panel</h2>
        </div>
        <ul className="sidebar-menu">
          <li><Link to="/categories">Categories</Link></li>
          <li><Link to="/categories/images">Categories-Images</Link></li>
          <li><Link to="/subcategories">Subcategories</Link></li>
          <li><Link to="/properties">Properties</Link></li>
          <li><Link to="/addproperties"> AddProperties</Link></li>
          <li><Link to="/upload-excel">Upload Excel</Link></li>
          <li><Link to="/online-payment">Online payment</Link></li>
          <li><Link to="/offline-payment">Offline payment</Link></li>
   
        </ul>
      </div>
    );
  };
  
  export default Sidebar;