import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    subcategoriesProperties: [], // Initialize as an empty array
  };
  
  const subcategoryPropertySlice = createSlice({
    name: 'subcategoryProperty',
    initialState,
    reducers: {
      setSubcategoriesProperties(state, action) {
        state.subcategoriesProperties = action.payload;
      },
    },
  });
  
  export const { setSubcategoriesProperties } = subcategoryPropertySlice.actions;
  export default subcategoryPropertySlice.reducer;
  