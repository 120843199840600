



import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory, uploadImage, deleteImage } from '../service/api'; // Use image-related APIs
import { setCategories } from '../redux/slice/categorySlice';

const ImageManager = () => {
  const [selectedCategory, setSelectedCategory] = useState(null); // Selected category from dropdown
  const [image, setImage] = useState(null); // For new image uploads
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);

  useEffect(() => {
    // Fetch categories on component mount
    const fetchCategories = async () => {
      try {
        const response = await getCategory();
        dispatch(setCategories(response.data.data));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [dispatch]);

  const handleCategorySelect = (id) => {
    const category = categories.find((cat) => cat.id === id);
    setSelectedCategory(category);
    setImage(null); // Clear any selected image
  };

  const handleUploadImage = async () => {
    if (!image || !selectedCategory) {
      alert('Please select a category and an image.');
      return;
    }

    const formData = new FormData();
    formData.append('image', image);
    formData.append('category_id', selectedCategory.id);

    try {
      await uploadImage(formData);

      const response = await getCategory();
      dispatch(setCategories(response.data.data));
      const updatedCategory = response.data.data.find((cat) => cat.id === selectedCategory.id);
      setSelectedCategory(updatedCategory);

      setImage(null); // Clear image input
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDeleteImage = async (imageId) => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      try {
        await deleteImage(imageId);

        // Refresh category details
        const response = await getCategory();
        dispatch(setCategories(response.data.data));
        const updatedCategory = response.data.data.find((cat) => cat.id === selectedCategory.id);
        setSelectedCategory(updatedCategory);
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    }
  };

  return (
    <div>
      {/* Dropdown for selecting category */}
      <label>Select Category</label>
      <select
        onChange={(e) => handleCategorySelect(parseInt(e.target.value))}
        value={selectedCategory?.id || ''}
      >
        <option value="" disabled>
          Select a category
        </option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>

      {/* Image Management Section */}
      {selectedCategory && (
        <div>
          <h3>Manage Images for {selectedCategory.name}</h3>

          {/* Image Upload */}
          <h4>Upload New Image</h4>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            accept="image/*"
          />
          <button onClick={handleUploadImage}>Upload Image</button>

          {/* List of Images */}
          <h4>Existing Images</h4>
          {selectedCategory.images && selectedCategory.images.length > 0 ? (
            <ul>
              {selectedCategory.images.map((img) => (
                <li key={img.id}>
                  <img
                    src={img.url}
                    alt="Category"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                  <button onClick={() => handleDeleteImage(img.id)}>Delete</button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No images available for this category</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageManager;
