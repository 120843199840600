

import React, { useState, useEffect } from 'react';
import {
  getSubcategories,
  getProperties,
  getSubcategoriesProperties,
  createSubcategoryPropertyLink,
  deleteSubcategoryPropertyLink,
  updateSubcategoryPropertyLink,
} from '../service/api';
import { useDispatch, useSelector } from 'react-redux';
import { setSubcategories } from '../redux/slice/subcategorySlice';
import { setProperties } from '../redux/slice/propertySlice';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, List, ListItem, ListItemText, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { setSubcategoriesProperties } from '../redux/slice/subcategoryPropertiesSlice';

const SubcategoryForm = () => {
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState('');
  const [selectedPropertyId, setSelectedPropertyId] = useState('');
  const [editingLinkId, setEditingLinkId] = useState(null); // ID of the link being edited
  const [newSubcategoryId, setNewSubcategoryId] = useState(''); // Updated subcategory
  const [newPropertyId, setNewPropertyId] = useState(''); // Updated property
  const [filterSubcategoryId, setFilterSubcategoryId] = useState(''); // Added state for filtering

  const subcategories = useSelector((state) => state.subcategory.subcategories);
  const properties = useSelector((state) => state.property.properties);
  const subcategoriesProperties = useSelector(
    (state) => state.subcategoryProperty.subcategoriesProperties
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subcategoryResponse = await getSubcategories();
        dispatch(setSubcategories(subcategoryResponse.data.data));

        const propertyResponse = await getProperties();
        dispatch(setProperties(propertyResponse.data));

        const subcategoryPropertyResponse = await getSubcategoriesProperties();
        dispatch(setSubcategoriesProperties(subcategoryPropertyResponse.data));
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleLinkProperty = async () => {
    try {
      if (selectedSubcategoryId && selectedPropertyId) {
        await createSubcategoryPropertyLink(selectedSubcategoryId, selectedPropertyId);
        alert('Property linked to subcategory successfully!');
      } else {
        alert('Please select both a subcategory and a property.');
      }
    } catch (error) {
      console.error('Failed to link property:', error);
    }
  };

  const handleUnlinkProperty = async (subcategoryId, propertyId) => {
    try {
      await deleteSubcategoryPropertyLink(subcategoryId, propertyId);
      alert('Property unlinked from subcategory successfully!');
    } catch (error) {
      console.error('Failed to unlink property:', error);
    }
  };

  const handleUpdatePropertyLink = async () => {
    try {
      if (editingLinkId && newSubcategoryId && newPropertyId) {
        await updateSubcategoryPropertyLink(editingLinkId, newSubcategoryId, newPropertyId);
        alert('Link updated successfully!');
        setEditingLinkId(null);
        setNewSubcategoryId('');
        setNewPropertyId('');
      } else {
        alert('Please select updated subcategory and property.');
      }
    } catch (error) {
      console.error('Failed to update link:', error);
    }
  };

  // Filter subcategoriesProperties by the selected subcategory
  const filteredLinks = subcategoriesProperties.filter((link) => {
    return filterSubcategoryId
      ? link.subcategory_id === parseInt(filterSubcategoryId)
      : true;
  });

  return (

      <div>
        {/* Form for Linking Properties to Subcategories */}
        <div>
          <h3>Link Properties to Subcategories</h3>
  
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Subcategory</InputLabel>
            <Select
              value={selectedSubcategoryId}
              onChange={(e) => setSelectedSubcategoryId(e.target.value)}
              label="Select Subcategory"
            >
              <MenuItem value="" disabled>Select Subcategory</MenuItem>
              {subcategories.map((subcategory) => (
                <MenuItem key={subcategory.id} value={subcategory.id}>
                  {subcategory.subcategory_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
  
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Property</InputLabel>
            <Select
              value={selectedPropertyId}
              onChange={(e) => setSelectedPropertyId(e.target.value)}
              label="Select Property"
            >
              <MenuItem value="" disabled>Select Property</MenuItem>
              {properties.map((property) => (
                <MenuItem key={property.id} value={property.id}>
                  {property.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
  
          <Button variant="contained" color="primary" onClick={handleLinkProperty}>Link Property</Button>
        </div>
  
        {/* Filter Linked Properties */}
        <div>
          <FormControl fullWidth margin="normal">
            <InputLabel>Filter Linked Properties by Subcategory</InputLabel>
            <Select
              value={filterSubcategoryId}
              onChange={(e) => setFilterSubcategoryId(e.target.value)}
              label="Filter Linked Properties by Subcategory"
            >
              <MenuItem value="">All Subcategories</MenuItem>
              {subcategories.map((subcategory) => (
                <MenuItem key={subcategory.id} value={subcategory.id}>
                  {subcategory.subcategory_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
  
        {/* List of Linked Properties */}
        <h3>Subcategories with Linked Properties</h3>
        <List>
          {filteredLinks.map((link) => {
            const subcategory = subcategories.find((sub) => sub.id === link.subcategory_id);
            const property = properties.find((prop) => prop.id === link.property_id);
  
            return (
              <ListItem key={link.id}>
                {editingLinkId === link.id ? (
                  <div>
                    {/* Editable Fields */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel>New Subcategory</InputLabel>
                      <Select
                        value={newSubcategoryId}
                        onChange={(e) => setNewSubcategoryId(e.target.value)}
                        label="New Subcategory"
                      >
                        <MenuItem value="" disabled>Select Subcategory</MenuItem>
                        {subcategories.map((subcategory) => (
                          <MenuItem key={subcategory.id} value={subcategory.id}>
                            {subcategory.subcategory_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
  
                    <FormControl fullWidth margin="normal">
                      <InputLabel>New Property</InputLabel>
                      <Select
                        value={newPropertyId}
                        onChange={(e) => setNewPropertyId(e.target.value)}
                        label="New Property"
                      >
                        <MenuItem value="" disabled>Select Property</MenuItem>
                        {properties.map((property) => (
                          <MenuItem key={property.id} value={property.id}>
                            {property.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
  
                    <Button variant="contained" color="primary" onClick={handleUpdatePropertyLink}>Save</Button>
                    <Button variant="outlined" color="secondary" onClick={() => setEditingLinkId(null)}>Cancel</Button>
                  </div>
                ) : (
                  <div>
                    <ListItemText
                      primary={<strong>{subcategory ? subcategory.subcategory_name : 'Unknown Subcategory'}</strong>}
                      secondary={`linked to ${property ? property.name : 'Unknown Property'}`}
                    />
                    <IconButton onClick={() => setEditingLinkId(link.id)}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleUnlinkProperty(link.subcategory_id, link.property_id)}><DeleteIcon /></IconButton>
                  </div>
                )}
              </ListItem>
            );
          })}
        </List>
      </div>
  
  );
};

export default SubcategoryForm;
