import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSubcategory, getSubcategories, updateSubcategory, deleteSubcategory, getCategory } from '../service/api';
import { setSubcategories } from '../redux/slice/subcategorySlice';
import { setCategories } from '../redux/slice/categorySlice';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl, Box, Typography, Grid } from '@mui/material';

const SubcategoryForm = () => {
  const [subcategoryName, setSubcategoryName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [maxPrice, setMaxPrice] = useState(''); // For max price
  const [minPrice, setMinPrice] = useState(''); // For min price
  const [editId, setEditId] = useState(null);
  const [filterCategoryId, setFilterCategoryId] = useState('');  // Added filter state
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);
  const subcategories = useSelector((state) => state.subcategory.subcategories);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response_1 = await getCategory();
        dispatch(setCategories(response_1.data.data));
        const response = await getSubcategories();
        console.log(response)
        dispatch(setSubcategories(response.data.data));
      } catch (error) {
        console.error('Failed to fetch subcategories');
      }
    };

    fetchSubcategories();
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editId) {
        // Update subcategory with price values
        await updateSubcategory(editId, { 
          subcategory_name: subcategoryName, 
          category_id:categoryId, 
          max_price: maxPrice, 
          min_price: minPrice 
        });
      } else {
        // Create new subcategory with price values
        await createSubcategory({ 
          subcategory_name: subcategoryName, 
          category_id: categoryId,
          max_price: maxPrice, 
          min_price: minPrice 
        });
      }

      const response = await getSubcategories();
      dispatch(setSubcategories(response.data.data));

      setSubcategoryName('');
      setCategoryId('');
      setMaxPrice('');
      setMinPrice('');
      setEditId(null);
    } catch (error) {
      console.error(editId ? 'Failed to update subcategory' : 'Failed to create subcategory');
    }
  };

  const handleEdit = (subcategory) => {
    console.log(subcategory)
    setEditId(subcategory.id);
    setSubcategoryName(subcategory.subcategory_name);
    setCategoryId(subcategory.category_id);
    setMaxPrice(subcategory.max_price || '');  // Set max price for editing
    setMinPrice(subcategory.min_price || '');  // Set min price for editing
  };

  const handleCancelEdit = () => {
    setEditId(null);
    setSubcategoryName('');
    setCategoryId('');
    setMaxPrice('');
    setMinPrice('');
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this subcategory?')) {
      try {
        await deleteSubcategory(id);
        const response = await getSubcategories();
        dispatch(setSubcategories(response.data.data));
      } catch (error) {
        console.error('Failed to delete subcategory');
      }
    }
  };

  // Filter the subcategories based on the selected category
  const filteredSubcategories = filterCategoryId
    ? subcategories.filter(subcategory => subcategory.category_id == filterCategoryId)
    : subcategories;

  return (
    <Box sx={{ p: 4 }}>
      {/* Form to Create or Edit Subcategories */}
      <Box sx={{ mb: 4, p: 3, borderRadius: 2, boxShadow: 2 }}>
        <Typography variant="h5">{editId ? 'Edit Subcategory' : 'Create Subcategory'}</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Subcategory Name"
            variant="outlined"
            fullWidth
            value={subcategoryName}
            onChange={(e) => setSubcategoryName(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth required sx={{ mb: 2 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              label="Category"
            >
              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Max Price"
            variant="outlined"
            fullWidth
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Min Price"
            variant="outlined"
            fullWidth
            type="number"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" type="submit" sx={{ width: '48%' }}>
              {editId ? 'Update Subcategory' : 'Create Subcategory'}
            </Button>
            {editId && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelEdit}
                sx={{ width: '48%' }}
              >
                Cancel
              </Button>
            )}
          </Box>
        </form>
      </Box>

      {/* Filter by Category */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Filter by Category</Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Select
            value={filterCategoryId}
            onChange={(e) => setFilterCategoryId(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>All Categories</em>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* List of Subcategories */}
      <Typography variant="h5" gutterBottom>Subcategories</Typography>
      <Grid container spacing={2}>
        {filteredSubcategories.length > 0 ? (
          filteredSubcategories.map((subcategory) => (
            <Grid item xs={12} sm={6} key={subcategory.id}>
              <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, textAlign: 'center', boxShadow: 1 }}>
                <Typography variant="h6">{subcategory.name}</Typography>
                <Typography variant="body2">Category: {subcategory.subcategory_name}</Typography>
                <Typography variant="body2">Max Price: {subcategory.max_price}</Typography>
                <Typography variant="body2">Min Price: {subcategory.min_price}</Typography>
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(subcategory)}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(subcategory.id)}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))
        ) : (
          <Typography>No subcategories available</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default SubcategoryForm;
