import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadExcel, getSubcategories } from '../service/api';
import { setSubcategories } from '../redux/slice/subcategorySlice';
import * as XLSX from 'xlsx';
import { Button, CircularProgress, Typography, Container, Box, Grid, Snackbar } from '@mui/material';

const FileUpload = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [error, setError] = useState('');
  const [isValidData, setIsValidData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const subcategories = useSelector((state) => state.subcategory.subcategories);

  // Fetch subcategories on component mount
  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await getSubcategories();
        dispatch(setSubcategories(response.data.data));
      } catch (error) {
        console.error('Failed to fetch subcategories');
      }
    };

    fetchSubcategories();
  }, [dispatch]);

  // Handle file input change
  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    setLoading(true);
    setError('');
    setExcelData([]);
    setIsValidData(false);

    // Read file and get sheet names
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      setSheetNames(workbook.SheetNames);
      setLoading(false);
    };
    reader.readAsArrayBuffer(uploadedFile);
  };

  // Handle sheet selection and parse sheet data
  const handleSheetSelection = (sheetName) => {
    setSelectedSheet(sheetName);
    setLoading(true);
    setError('');
    setIsValidData(false);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

      const columns = Object.keys(jsonData[0]);
      if (!columns.includes('subcategory_id')) {
        setError('The "subcategory_id" column is missing in the sheet.');
        setIsValidData(false);
        setExcelData([]);
        setLoading(false);
        return;
      }

      const validateData = () => {
        jsonData.forEach((item) => {
          const subcategoryId = item.subcategory_id;
          const isSubcategoryValid = subcategories.some((sub) => sub.id === subcategoryId);
          if (!isSubcategoryValid) {
            setError(`Subcategory ID ${subcategoryId} is invalid.`);
            setIsValidData(false);
            setExcelData([]);
            setLoading(false);
            return;
          }
        });
        setExcelData(jsonData);
        setIsValidData(true);
        setLoading(false);
      };

      validateData();
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFinalSubmit = () => {
    if (isValidData) {
      setLoading(true);
      const data = {
        tablename: selectedSheet,
        exc_data: excelData,
      };
      uploadExcel(data)
        .then(() => {
          setLoading(false);
          setSuccessMessage('Data submitted successfully!');
        })
        .catch(() => {
          setLoading(false);
          setError('Error submitting data. Please try again.');
        });
    } else {
      setError('Please fix any errors before submitting.');
    }
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
  {subcategories.length > 0 ? (
    subcategories.map((subcategory) => (
      <Grid item xs={12} sm={6} md={4} key={subcategory.id}>
        <Box
          sx={{
            border: '1px solid #ddd',
            borderRadius: 1,
            padding: 2,
            textAlign: 'center',
            backgroundColor: '#f5f5f5',
            boxShadow: 2,
          }}
        >
          <Typography variant="body2">{subcategory.id} - {subcategory.subcategory_name}</Typography>
        </Box>
      </Grid>
    ))
  ) : (
    <Typography>No subcategories available</Typography>
  )}
</Grid>

      <Box mt={4} p={3} sx={{ borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom>Upload Excel Data</Typography>

        <form>
          <input
            type="file"
            onChange={handleFileChange}
            accept=".xlsx, .xls"
            style={{ display: 'none' }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button variant="contained" component="span" color="primary" fullWidth>
              Choose Excel File
            </Button>
          </label>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={() => setFile(null)}
          >
            Clear File
          </Button>
        </form>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
            <CircularProgress />
          </Box>
        )}

        {sheetNames.length > 0 && !loading && (
          <Box mt={3}>
            <Typography variant="h6">Select a Sheet</Typography>
            <Grid container spacing={1}>
              {sheetNames.map((name, index) => (
                <Grid item key={index}>
                  <Button
                    variant="outlined"
                    onClick={() => handleSheetSelection(name)}
                    color="primary"
                  >
                    {name}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {error && (
          <Typography color="error" mt={2}>{error}</Typography>
        )}

        {selectedSheet && excelData.length > 0 && (
          <Box mt={3}>
            <Typography variant="h6">Data from Sheet: {selectedSheet}</Typography>
            <table border="1" style={{ width: '100%', marginTop: 20 }}>
              <thead>
                <tr>
                  {Object.keys(excelData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {excelData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((cell, i) => (
                      <td key={i}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        )}

        <Box mt={4}>
          <Button
            variant="contained"
            color="success"
            onClick={handleFinalSubmit}
            fullWidth
            disabled={!isValidData || loading}
          >
            Submit Data
          </Button>
        </Box>

        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage('')}
          message={successMessage}
        />
      </Box>
    </Container>
  );
};

export default FileUpload;
