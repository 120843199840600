import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createProperty,
  getProperties,
  updateProperty,
  deleteProperty,
} from '../service/api';
import { setProperties } from '../redux/slice/propertySlice';
import { Button, TextField, Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const PropertyForm = () => {
  const [propertyName, setPropertyName] = useState('');
  const [editPropertyId, setEditPropertyId] = useState(null); // For editing
  const properties = useSelector((state) => state.property.properties); // Redux state
  const dispatch = useDispatch();

  // Fetch properties on component mount
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await getProperties();
        console.log(response);
        dispatch(setProperties(response.data)); // Populate Redux store
      } catch (error) {
        console.error('Failed to fetch properties:', error);
      }
    };

    fetchProperties();
  }, [dispatch]);

  // Create or Update a property
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editPropertyId) {
        // Update operation
        await updateProperty(editPropertyId, { name: propertyName });
        alert('Property updated successfully!');
      } else {
        // Create operation
        const response = await createProperty({ name: propertyName });
        dispatch(setProperties([...properties, response.data])); // Add new property to Redux store
        alert('Property created successfully!');
      }
      setPropertyName('');
      setEditPropertyId(null); // Reset form
    } catch (error) {
      console.error('Failed to save property:', error);
    }
  };

  // Edit a property
  const handleEdit = (property) => {
    setEditPropertyId(property.id);
    setPropertyName(property.name);
  };

  // Delete a property
  const handleDelete = async (id) => {
    try {
      await deleteProperty(id);
      const updatedProperties = properties.filter((prop) => prop.id !== id);
      dispatch(setProperties(updatedProperties)); // Update Redux store
      alert('Property deleted successfully!');
    } catch (error) {
      console.error('Failed to delete property:', error);
    }
  };

  return (
<Box sx={{ p: 3 }}>
      {/* Form to Create or Edit Property */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5">{editPropertyId ? 'Edit Property' : 'Create Property'}</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label={editPropertyId ? 'Edit Property Name' : 'Property Name'}
            variant="outlined"
            fullWidth
            value={propertyName}
            onChange={(e) => setPropertyName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" type="submit" sx={{ width: '48%' }}>
              {editPropertyId ? 'Update Property' : 'Create Property'}
            </Button>
            {editPropertyId && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setPropertyName('');
                  setEditPropertyId(null);
                }}
                sx={{ width: '48%' }}
              >
                Cancel
              </Button>
            )}
          </Box>
        </form>
      </Box>

      {/* List of Properties */}
      <Typography variant="h6" gutterBottom>Properties</Typography>
      <List>
        {properties.map((property) => (
          <ListItem key={property.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText primary={property.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" color="primary" onClick={() => handleEdit(property)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" color="secondary" onClick={() => handleDelete(property.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PropertyForm;

