import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  date: '',
  orderstatus: '',
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setEmailFilter: (state, action) => {
      state.email = action.payload;
    },
    setDateFilter: (state, action) => {
      state.date = action.payload;
    },
    setStatusFilter: (state, action) => {
      state.orderstatus = action.payload;
    },
    resetFilters: () => initialState,
  },
});

export const { setEmailFilter, setDateFilter, setStatusFilter, resetFilters } = filtersSlice.actions;
export default filtersSlice.reducer;
