import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from './slice/categorySlice';
import subcategoryReducer from './slice/subcategorySlice';
import propertyReducer from './slice/propertySlice';
import  subcategoryPropertyReducer   from './slice/subcategoryPropertiesSlice'
import  ordersReducer   from  './slice/orderslice'
import  filterReducer from './slice/filterSlice'
import OrdersOffListReducer from './slice/orderoOfSlice';



export const store = configureStore({
  reducer: {
    category: categoryReducer,
    subcategory: subcategoryReducer,
    property: propertyReducer,
    subcategoryProperty: subcategoryPropertyReducer,
    orders: ordersReducer,
    filters:filterReducer,
    orderoffline:OrdersOffListReducer


  },
});
