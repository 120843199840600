import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEmailFilter, setDateFilter, setStatusFilter } from '../redux/slice/filterSlice';
import { setOrders, setLoading, setError } from '../redux/slice/orderslice.js';
import { getOnlinePaymentOrder, updateOrderStatus } from "../service/api.js";
import '../css/OrderComponet.css'

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
const OrdersList = () => {
  const dispatch = useDispatch();
  const orders = useSelector(state => state.orders.orders);
  const filters = useSelector(state => state.filters);
  const loading = useSelector(state => state.orders.loading);
  const error = useSelector(state => state.orders.error);

  const emailOptions = [...new Set(orders.map(order => order.user_email))];
  const dateOptions = [...new Set(orders.map(order => order.created_at.split('T')[0]))];
  const statusOptions = ['processing', 'cancelled'];

  const handleEmailChange = (e) => dispatch(setEmailFilter(e.target.value));
  const handleDateChange = (e) => dispatch(setDateFilter(e.target.value));
  const handleStatusChange = (e) => dispatch(setStatusFilter(e.target.value));

  const filterOrders = () => {
    return orders.filter(order => {
      const matchesEmail = filters.email ? order.user_email.includes(filters.email) : true;
      const matchesDate = filters.date ? order.created_at.includes(filters.date) : true;
      const matchesStatus = filters.orderstatus ? order.orderstatus === filters.orderstatus : true;
      return matchesEmail && matchesDate && matchesStatus;
    });
  };

  // const handleStatusUpdate = async (orderId, newStatus) => {

  //   try {
  //     const response = await updateOrderStatus(orderId, newStatus); // Call API to update status
  //     console.log(`Order ${orderId} status updated to ${newStatus}`);
  //     alert(`Order ${orderId} status updated to ${newStatus}`);
  //     const updatedOrders = orders.map(order =>
  //       order.paymemt_id === orderId ? { ...order, orderstatus: newStatus } : order
  //     );
  //     dispatch(setOrders(updatedOrders)); // Update orders in Redux store
  //   } catch (error) {
  //     console.error('Error updating order status:', error);
  //   }
  // };
  
  const handleStatusUpdate = async (orderId, newStatus) => {
    const confirmChange = window.confirm(`Are you sure you want to mark this order as ${newStatus}?`);

    if (confirmChange) {
      try {
        const response = await updateOrderStatus(orderId, newStatus); // API call to update order status
        console.log(`Order ${orderId} status updated to ${newStatus}`);
        
        // Show success alert
        // alert(`Order ${orderId} status updated to ${newStatus}`);

        const updatedOrders = orders.map(order =>
          order.paymemt_id === orderId ? { ...order, orderstatus: newStatus } : order
        );
        dispatch(setOrders(updatedOrders)); // Update orders in Redux store
      } catch (error) {
        console.error('Error updating order status:', error);
        
        // Show error alert
        alert('Failed to update order status. Please try again later.');
      }
    } else {
      // If the user cancels the action, show a cancel alert
      alert('Order status update was canceled.');
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      dispatch(setLoading(true)); // Start loading
      try {
        const response = await getOnlinePaymentOrder(); // API call
        dispatch(setOrders(response.data.data)); // Dispatch orders data
        dispatch(setLoading(false)); // Stop loading
      } catch (error) {
        console.error('Error fetching orders:', error);
        dispatch(setError('Error fetching orders')); // Set error message
        dispatch(setLoading(false)); // Stop loading
      }
    };

    fetchOrders(); // Invoke the async function
  }, [dispatch]);

  return (
    <Box className="orders-list-container">
    <Typography variant="h4" gutterBottom>
      Filter Options
    </Typography>

    <Box display="flex" gap={2} mb={3} className="filters">
      {/* Email Filter */}
      <FormControl fullWidth>
        <InputLabel>Select Email</InputLabel>
        <Select value={filters.email} onChange={handleEmailChange}>
          <MenuItem value="">Select Email</MenuItem>
          {emailOptions.map((email) => (
            <MenuItem key={email} value={email}>
              {email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Date Filter */}
      <FormControl fullWidth>
        <InputLabel>Select Date</InputLabel>
        <Select value={filters.date} onChange={handleDateChange}>
          <MenuItem value="">Select Date</MenuItem>
          {dateOptions.map((date) => (
            <MenuItem key={date} value={date}>
              {date}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Status Filter */}
      <FormControl fullWidth>
        <InputLabel>Select Status</InputLabel>
        <Select value={filters.status} onChange={handleStatusChange}>
          <MenuItem value="">Select Status</MenuItem>
          {statusOptions.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>

    {/* Loading Spinner */}
    {loading && (
      <Box textAlign="center" my={3}>
        <CircularProgress />
      </Box>
    )}

    {/* Error Message */}
    {error && (
      <Typography variant="body1" color="error" textAlign="center" my={3}>
        {error}
      </Typography>
    )}

    {/* Orders List */}
    <Box className="orders-list">
      {filterOrders().map((order) => (
        <Card key={order.paymemt_id} variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6">{order.user_name}</Typography>
            <Typography variant="body2">Email: {order.user_email}</Typography>
            <Typography variant="body2">Amount: {order.amount}</Typography>
            <Typography variant="body2">Status: {order.status}</Typography>
            <Typography variant="body2">City: {order.city}</Typography>
            <Typography variant="body2">Address: {order.street_address}</Typography>
            <Typography variant="body2">
              Date: {new Date(order.created_at).toLocaleDateString()}
            </Typography>
            <Typography variant="body2">Order Status: {order.orderstatus}</Typography>

            {/* Products Section */}
            <Box className="products" mt={2}>
              <Typography variant="subtitle1">Products:</Typography>
              {JSON.parse(order.products_details).map((product, index) => (
                <Box key={index} className="product-item" mt={1}>
                  <Typography variant="body2">Product Name: {product.product}</Typography>
                  <Typography variant="body2">Price: {product.price}</Typography>
                  <Typography variant="body2">Quantity: {product.quantity}</Typography>
                  <Typography variant="body2">Selected Options:</Typography>
                  <List dense>
                    {Object.entries(product.selectedOptions).map(([option, value], i) => (
                      <ListItem key={i}>
                        <ListItemText primary={`${option}: ${value}`} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </Box>

            {/* Status Action Buttons */}
            <Box mt={3} className="status-actions" display="flex" gap={1}>
              <Button
                variant="contained"
                color="info"
                onClick={() => handleStatusUpdate(order.paymemt_id, 'processing')}
              >
                Mark as Processing
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={() => handleStatusUpdate(order.paymemt_id, 'cancelled')}
              >
                Cancel Order
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => handleStatusUpdate(order.paymemt_id, 'delivered')}
              >
                Mark as Delivered
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  </Box>
);
  
};

export default OrdersList;
