import React from 'react';
import { store } from './redux/store'; // Correct import for named export
import { Provider } from 'react-redux'; // To connect React with Redux
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CategoryForm from './components/CategoryForm'; // Corrected typo
import Subcategories from './components/SubcategoryForm'; // Ensure file name matches
import Properties from './components/PropertyForm'; // Ensure file name matches
import ExcelUpload from './components/FileUpload'; // Corrected file name
import Sidebar from './components/Sidebar'; // Sidebar component
import AddProperties  from './components/AddProperties'
import OrdersList from './components/OrderComponet';
import ImageManager  from './components/CategoryImages';
import OrdersOffList  from './components/OrderOffComonet'


const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="app-container">
          {/* Sidebar for navigation */}
          <Sidebar />
      
          <div className="content-container">
            <Routes>
              {/* Define routes to render the components */}
              <Route path="/categories" element={<CategoryForm />} />
              <Route path="/subcategories" element={<Subcategories />} />
              <Route path="/categories/images" element={<ImageManager/>} />
       
              <Route path="/properties" element={<Properties />} />
              <Route path="/upload-excel" element={<ExcelUpload />} />
              <Route path="/addproperties" element={< AddProperties/>} />
              <Route path ="/online-payment"  element={<OrdersList/>}/>
              <Route path ="/offline-payment" element={<OrdersOffList/>}/>
            </Routes>
          </div>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
