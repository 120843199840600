import axios from 'axios';
const api = axios.create({
  baseURL: '/m1-version',  // Replace with your actual API endpoint
});
export const getOfflinePaymentOrder  =  (id) =>{ 
  console.log("offline")
  return api.get(`/offline-payment`);

}
export const getOnlinePaymentOrder  =  () =>{ 
  console.log("online")
  return api.get(`/online-payment`);
}
export const updateOrderStatus = async (id, newStatus) => {
  console.log(id,newStatus)
 const fullURL = `${api.defaults.baseURL}/updateStatusHistoryOnline`;
 console.log(fullURL);
   const response =  await api.post('/updateStatusHistoryOnline', { id, newStatus });
   console.log(response)
   
};
export const updateOrderOfStatus = async (id, newStatus) => {
  console.log(id,newStatus)
 const fullURL = `${api.defaults.baseURL}/updateStatusHistoryOffline`;
 console.log(fullURL);
   const response =  await api.post('/updateStatusHistoryOffline', { id, newStatus });
   console.log(response)
   
};
// API for Categories
export const createCategory = (categoryData) => {
  console.log(categoryData);
  // POST request for creating a category
  return api.post('/admin/create-category', categoryData);
};

// API for Getting Categories (either all or by ID)
export const getCategory = (id = null) => {
  console.log(id);
  // Use `id` parameter to either get all categories or a specific category
  if (id) {
    return api.get(`/admin/categories/${id}`); // Get a category by ID
  } else {
    return api.get('/admin/categories'); // Get all categories
  }
};






// API for Updating a Category
export const updateCategory = (id, categoryData) => {
  return api.put(`/admin/category/${id}`, categoryData); // PUT request to update category by ID
};

// API for Deleting a Category
export const deleteCategory = (id) => {
  return api.delete(`/admin/category/${id}`); // DELETE request to remove category by ID
};

// // API for Subcategories




export const createSubcategory = async (data) => {
   console.log(data)
  return await api.post('/admin/subcategories', data);
};

export const getSubcategories = async () => {
  return await api.get('/admin/subcategories');
};

export const updateSubcategory = async (id, data) => {
   console.log(id,data)
  return await api.put(`/admin/subcategories/${id}`, data);
};

export const deleteSubcategory = async (id) => {
  return await api.delete(`/admin/subcategories/${id}`);
};




// API for Excel Upload
export const uploadExcel =async (formData) => {

  console.log(formData);
   return  await api.post('admin/generateAndExecuteQuery', formData); // POST request for Excel file upload

}
  // Service/api.js



export const createProperty = async (propertyName) => {
     console.log(propertyName);
  const response = await api.post(`/admin/properties`, { name: propertyName.name});
  return response.data;
};



export const getProperties = async (propertyName) => {
  console.log(propertyName);
const response = await api.get(`/admin/properties`);
return response.data;
};

// Update a property
export const updateProperty = async (propertyId, propertyName) => {
  const response = await api.put(`/admin/properties/${propertyId}`, { name: propertyName });
  return response.data;
};

// Delete a property
export const deleteProperty = async (propertyId) => {
  const response = await api.delete(`/admin/properties/${propertyId}`);
  return response.data;
};

// Get subcategories-properties links
export const getSubcategoriesProperties = async () => {
  const response = await api.get(`/admin/subcategories-properties`);
  return response.data;
};

// Link a property to a subcategory
export const createSubcategoryPropertyLink = async (subcategoryId, propertyId) => {
    console.log(subcategoryId,propertyId)
  const response = await api.post(`/admin/link`, { subcategory_id:subcategoryId, property_id:propertyId });
  return response.data;
};

export const getPropertiesBySubcategory = async (subcategoryId) => {
  try {
    const response = await api.get(`/admin/subcategory/${subcategoryId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching properties for subcategory:', error);
    throw error;
  }
};

export const updateSubcategoryPropertyLink = async (id, subcategoryId, propertyId) => {
  try {
    const response = await api.put(`/admin/link/update/${id}`, {
      subcategory_id: subcategoryId,
      property_id: propertyId,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating subcategory-property link:', error);
    throw error;
  }
};

// Unlink a property from a subcategory
export const deleteSubcategoryPropertyLink = async (subcategoryId, propertyId) => {
  const response = await api.delete(`/admin/link/:id/${subcategoryId}/${propertyId}`);
  return response.data;
};

export const uploadImage = async (formData) => {
  return await api.post('/categories/image', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteImage = async (imageId) => {
  return await api.delete(`/admin/images/${imageId}`);
};
