import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, getCategory, updateCategory, deleteCategory } from '../service/api';
import { setCategories, setLoading, setError } from '../redux/slice/categorySlice';
import { Button, TextField, Grid, Container, Box, Typography } from '@mui/material';

const CategoryForm = () => {
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [editId, setEditId] = useState(null); // To track the category being edited
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategory();
        dispatch(setCategories(response.data.data));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
      if (editId) {
        // Update existing category
        await updateCategory(editId, { categoryName, description });
      } else {
        // Create new category
        await createCategory({ categoryName, description });
      }

      // Refresh the category list
      const response = await getCategory();
      dispatch(setCategories(response.data.data));

      // Clear the form and reset edit mode
      setCategoryName('');
      setDescription('');
      setEditId(null);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError('Failed to create or update category'));
      dispatch(setLoading(false));
    }
  };

  const handleEdit = (category) => {
    setEditId(category.id);
    setCategoryName(category.name);
    setDescription(category.description);
  };

  const handleCancelEdit = () => {
    setEditId(null);
    setCategoryName('');
    setDescription('');
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await deleteCategory(id); // Delete category via API

        // Refresh the category list after deletion
        const response = await getCategory();
        dispatch(setCategories(response.data.data));
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  return (
    <Container maxWidth="sm">
      {/* Category Form */}
      <Box mt={4} p={3} sx={{ borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom>{editId ? 'Edit Category' : 'Create Category'}</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Category Name"
            variant="outlined"
            fullWidth
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            required
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            sx={{ marginBottom: 2 }}
          />
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ width: '48%' }}
            >
              {editId ? 'Update Category' : 'Create Category'}
            </Button>
            {editId && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelEdit}
                sx={{ width: '48%' }}
              >
                Cancel
              </Button>
            )}
          </Box>
        </form>
      </Box>

      {/* Render List of Categories */}
      <Box mt={4} p={3} sx={{ borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h5" gutterBottom>Categories</Typography>
        <Grid container spacing={2}>
          {categories && categories.length > 0 ? (
            categories.map((category) => (
              <Grid item xs={12} sm={6} key={category.id}>
                <Box
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    padding: 2,
                    textAlign: 'center',
                    backgroundColor: '#f5f5f5',
                    boxShadow: 1,
                  }}
                >
                  <Typography variant="h6">{category.name}</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {category.description}
                  </Typography>
                  <Box mt={2} display="flex" justifyContent="space-evenly">
                    <Button
                      variant="outlined"
                      onClick={() => handleEdit(category)}
                      color="primary"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handleDelete(category.id)}
                      color="secondary"
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography>No categories available</Typography>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default CategoryForm;
